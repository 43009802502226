<template>
  <v-container>
    <v-card>
      <v-card-title>
        Enter a new password
      </v-card-title>

      <v-container>
        <v-row justify="center" v-if="loading">
          <v-progress-circular
            :size="100"
            indeterminate
            color="primary"
            class="ma-4"
          ></v-progress-circular>
        </v-row>
        <v-row v-if="!loading && !status">
          <v-col>
            <v-form
              ref="newPasswordForm"
              v-model="valid"
              @submit.prevent="submit"
            >
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    v-model="password"
                    label="New Password"
                    :rules="[...passwordRules, passwordMatch]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    v-model="passwordConfirm"
                    label="Confirm Password"
                    :rules="[...passwordRules, passwordMatch]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-end">
                  <v-btn
                    color="primary"
                    :disabled="!valid"
                    class="mr-4"
                    type="submit"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row v-else-if="!loading && status" justify="center">
          <div class="ma-8">
            <h1>{{ statusMessage }}</h1>
          </div>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    status: null,
    valid: false,
    loading: false,
    password: "",
    passwordConfirm: "",
    passwordRules: [
      v => !!v || "Password is required",
      v => v.length >= 8 || "Password must be greater than 7 characters"
    ]
  }),
  computed: {
    passwordMatch() {
      return this.password === this.passwordConfirm || "Password must match";
    },
    statusMessage() {
      if (this.status === 200) {
        return "Your password has been succesfully changed";
      } else if (this.status === 404) {
        return "This token is no longer valid. Please try reseting your password again.";
      }

      return "Error reseting password";
    }
  },
  methods: {
    ...mapActions("auth", ["passwordReset"]),
    async submit() {
      try {
        this.loading = true;
        const res = await this.passwordReset({
          password: this.password,
          token: this.$route.query.token
        });
        this.status = res.status;
      } catch (error) {
        this.status = error.response.status;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
